<template>
  <div class="wrapper">
    <div class="cancel">
      <router-link to="/">
        <CloseIcon />
      </router-link>
    </div>

    <div class="auth-banner">
      <img src="/img/chop-banner.webp" style="width: 100%; border-radius: 10px; max-width: 100%" />
    </div>

    <section class="p-4">
      <div style="padding: 5px">
        <h4 class="text-dark text-center activate_your_account">
          {{ $t("activateAccount") }}
          <span class="text-dark">{{ $t("code") }}</span>
        </h4>
        <p class="verify_phone" style="padding: 0 10px">
          {{ $t("verifyPhoneNumber") }}
          <strong>+{{ this.msisdn || "--" }} </strong>
        </p>

        <p
          @click="handlePhoneNumberEdit"
          style="
            text-transform: capitalize;
            text-align: center;
            font-size: 10px;
            cursor: pointer;
            font-family: 'Game Station';
            color: var(--slide-active);
          "
        >
          {{ $t("editPhoneNumber") }}
        </p>
      </div>

      <div class="form-wrapper mb-1">
        <form>
          <div class="otp-container">
            <input
              v-for="(value, index) in code"
              :key="index"
              class="otp-input"
              type="tel"
              maxlength="1"
              v-model="code[index]"
              @input="handleInput(index, $event)"
              @keydown.backspace="handleBackspace(index)"
            />
          </div>
          <small class="text mb-3">{{ $t("enter4DigitCode") }}</small>
        </form>
      </div>

      <div>
        <p
          v-if="spinner"
          style="text-transform: capitalize; text-align: center"
        >
          {{ $t("loading") }}...
        </p>

        <div
          v-if="allowResendTimer"
          class="login-button-wrapper text-center p-3 mb-3"
        >
          <div
            class="text-center mb-3 allow_resend_text"
            :style="{ color: resendSecond > 0 ? 'gray' : 'var(--dark-red)' }"
          >
            {{ $t("resendCodein") }} {{ this.resendSecond }} {{ $t("seconds") }}
          </div>
        </div>
        <div
          v-if="allowResend"
          class="login-button-wrapper text-center p-3 mb-3"
        >
          <div
            class="text-center mb-3 allow_resend_text"
            style="color: var(--dark-red)"
            @click="handleTokenResend"
          >
            {{ $t("resendCodeText") }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";
import CloseIcon from "../components/CloseIcon.vue";

export default {
  name: "Account-Activation",

  comments: { CloseIcon },
  data: function () {
    return {
      first_name: "",
      last_name: "",
      msisdn: "",
      code: new Array(4).fill(""),
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      allowResend: false,
      allowResendTimer: false,
      resendSecond: 30,
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
    };
  },
  watch: {
    code: {
      handler() {
        if (this.code.join("").length === 4) {
          this.handleVerifyClick();
          this.allowResendTimer = true;
          this.startCountdown();
        }
      },
      deep: true,
    },
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralCode"]),
  },
  methods: {
    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },

    handlePhoneNumberEdit() {
      this.$router.push("/join");
    },
    startCountdown() {
      this.resendSecond = 30;
      const countdownInterval = setInterval(() => {
        this.resendSecond--;
        if (this.resendSecond === 0) {
          clearInterval(countdownInterval);
          this.allowResend = true;
          this.allowResendTimer = false;
        }
      }, 1000);
    },
    trackVerifyButtonClick(event) {
      pushToDataLayer("gtm.verify", {
        category: "Button",
        action: "Click",
        label: "Verify",
        element: event.target,
      });
    },

    handleVerifyClick(event) {
      this.verifyAccount(event);
    },
    handleTokenResend(event) {
      this.tokenResend(event);
    },

    handleInput(index, event) {
      if (event.target.value.length === 1) {
        const nextInput = this.$el.querySelectorAll(".otp-input")[index + 1];
        if (nextInput) nextInput.focus();
      }
    },
    handleBackspace(index) {
      if (this.code[index] === "") {
        const prevInput = this.$el.querySelectorAll(".otp-input")[index - 1];
        if (prevInput) prevInput.focus();
      }
    },

    verifyAccount: function () {
      this.reset();
      const msisdn = localStorage.getItem("mssdn__reg_xpk");

      if (this.code.length < 4) {
        this.setError(`${this.$t("validVerificationCode")}`);
        return;
      }
      if (this.msisdn === null) {
        this.setError(`${this.$t("invalidPhoneNumber")}`);
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_URL_VERIFY}?lang=${this.$i18n.locale}`;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || "";
      var utm_medium = this.getValue("utm_medium") || "";
      var utm_campaign = this.getValue("utm_campaign") || "";
      const fullCode = this.code.join("");

      var payload = {
        phone_number: msisdn,
        code: parseInt(fullCode),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
      };

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";
          var profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          // vm.trackVerifyButtonClick(event);

          vm.setSuccess(
            "Success",
            `${this.$t("successfullAccountVerification")}`
          );

          vm.EventBus.$emit("init:mqtt");

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit("event:betslip:show");
          } else {
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";
          console.log("Error:", err);
          console.log("err fd", err);

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Request:", err.request);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Message:", err.message);
          }
        });
    },
    tokenResend: function () {
      this.reset();
      const msisdn = localStorage.getItem("mssdn__reg_xpk");

      if (this.msisdn === null) {
        this.setError(`${this.$t("invalidPhoneNumber")}`);
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_BASE_IDENTITY_URL}/token/resend?lang=${this.$i18n.locale}`;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var payload = {
        phone_number: `${msisdn}`,
      };

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";
          var profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          // vm.trackVerifyButtonClick(event);

          vm.setSuccess("Success", `${this.$t("resentOtp")}`);

          vm.EventBus.$emit("init:mqtt");
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";
          console.log("Error:", err);
          console.log("err fd", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Request:", err.request);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Message:", err.message);
          }
        });
    },
  },
  mounted: function () {
    const msisdn = localStorage.getItem("mssdn__reg_xpk");
    this.msisdn = msisdn;
  },
};
</script>

<style src="./index.css" scoped></style>
